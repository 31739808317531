
import { Component, Vue } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { manualAPIActions } from '@/helpers/ManiualAPIHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import NotAllowedComponent from '@/components/views/settings/NotAllowedComponent.vue';

@Component({
    name: 'ManualAPIRefresh',
    components: { SettingsTopBar, NotAllowedComponent },
})
export default class ManualAPIRefresh extends mixins<UserRightsMixin>(UserRightsMixin) {
    private backRoute: RouteNames = RouteNames.settings;
    private userHasRights: boolean = false;
    private disableButtons: boolean = true;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get actions() {
        return manualAPIActions();
    }

    private created() {
        if (this.isSuperAdmin) {
            this.userHasRights = true;
        }
    }

    private openConfirmUpdateProductFormPopup(action: any) {
        this.$confirm({
            title: action.modalTitle,
            content: '',
            okText: this.$t('Da') as string,
            okType: 'danger',
            cancelText: this.$t('Ne') as string,
            onOk: async () => {
                this.loadingOverlay.start();
                try {
                    await action.action();
                } catch (e) {
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: (e as Error).message,
                    });
                    this.loadingOverlay.stop();
                    return;
                }
                this.$notification.success({
                    message: action.successMessage as string,
                    description: '',
                });
                this.loadingOverlay.stop();
            },
        });
    }
}
